import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../../components/Layout';
import atv from '../../assets/images/atv.jpeg';
import boat from '../../assets/images/boat.jpeg';
import car from '../../assets/images/car.webp';
import home from '../../assets/images/home.jpg';
import landlord from '../../assets/images/landlord.webp';
import motorcycle from '../../assets/images/motorcycle.jpeg';
import renters from '../../assets/images/renters.jpg';
import RV from '../../assets/images/RV.jpeg';
import umbrella from '../../assets/images/umbrella.jpg';
import realtors from '../../assets/images/realtors.jpeg';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const products = [
  {
    name: 'Home',
    photo: home,
    href: '/Products/home',
  },
  {
    name: 'Auto',
    photo: car,
    href: '/Products/auto',
  },
  {
    name: 'Umbrella',
    photo: umbrella,
    href: '/Products/umbrella',
  },
  {
    name: 'Renters',
    photo: renters,
    href: '/Products/renters',
  },
  {
    name: 'Landlord',
    photo: landlord,
    href: '/Products/landlord',
  },
  {
    name: 'Motorcycle',
    photo: motorcycle,
    href: '/Products/motorcycle',
  },
  {
    name: 'ATV',
    photo: atv,
    href: '/Products/atv',
  },
  {
    name: 'Boat',
    photo: boat,
    href: '/Products/boat',
  },
  {
    name: 'RV',
    photo: RV,
    href: '/Products/rv',
  },
  {
    name: 'Realtors',
    photo: realtors,
    href: '/Products/realtors',
  },
];

export const ProductGrid = () => {
  return (
    <div
      className="inner"
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
      }}
    >
      {products.map((p) => (
        <Product name={p.name} photo={p.photo} href={p.href} />
      ))}
    </div>
  );
};

const Products = () => {
  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Products</h2>
        </header>
        <section className="wrapper style5">
          <ProductGrid />
        </section>
      </article>
    </Layout>
  );
};

const Product = ({ name, photo, href }) => {
  return (
    <div
      style={{
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        width: 300,
        height: 300,
        margin: '25px 5px',
        cursor: 'pointer',
      }}
      onClick={() => (window.location.href = href)}
    >
      <img
        src={photo}
        alt="Avatar"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <p style={{ textAlign: 'center' }}>{name}</p>
    </div>
  );
};

export default Products;
